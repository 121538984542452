.gameWrapper {
  opacity: 0;
  position: relative;
  height: 100vh;
  transition: 0.3s linear;
  &.open {
    opacity: 1;
  }
  @media screen and (min-width: 768px) {
    padding: 10px 0 0;
    margin: 0 auto;
    max-width: 600px;
    background-image: url("/assets/wordle/bg-game-desktop.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
}

.gameHeader {
  padding: 12px 10px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: 371px;
    border-radius: 16px;
  }
}

.gameBtnClose {
  width: 36px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #d1091e;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gameTime {
  display: flex;
  align-items: center;
  padding: 6px 11px;
  background-color: #d1091e;
  border-radius: 100px;
  font-family: "Avenir", sans-serif;
  font-weight: 900;
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  color: #fff;
  @media screen and (min-width: 768px) {
    padding: 6px 28px;
  }
}

.gameSpinner {
  width: 16px;
  height: 16px;
  border: 4px solid #ed7f7f;
  border-top: 4px solid #fff;
  border-radius: 50%;
  margin-right: 5px;
  animation: spin 2s linear infinite;
}

.gameBody {
  overflow: auto;
  padding: 20px 0 0;
  height: calc(100% - 230px);
  background-image: url("/assets/wordle/bg-game-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  @media screen and (min-width: 768px) {
    padding: 28px 0 0;
    height: auto;
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    background-position: unset;
    background-attachment: unset;
  }
}

.gameTitle {
  padding: 0;
  margin: 0 0 20px;
  font-family: "Fjalla One", sans-serif;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #d1091e;
  @media screen and (min-width: 768px) {
    margin: 0 0 16px;
    letter-spacing: 0.04em;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gameKeyboard {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 17px 10px 10px;
  background-color: #fff;
  border-top: 1px solid #515151;
  border-bottom: 1px solid #515151;

  @media screen and (min-width: 768px) {
    padding: 8px;
    border-top: none;
    margin: 0 auto;
    max-width: 371px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
}

.gameKeyboardDark {
  background-color: #272728;
}

.gameKeyboardTop,
.gameKeyboardCenter,
.gameKeyboardBottom {
  display: grid;
  place-content: center;
  gap: 5px;
}

.gameKeyboardTop {
  grid-template-columns: repeat(10, 1fr);
  padding-bottom: 8px;

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(10, 31px);
  }
}

.gameKeyboardCenter {
  grid-template-columns: repeat(9, 1fr);
  padding: 0 18px 8px;

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(9, 31px);
  }
}

.gameKeyboardBottom {
  grid-template-columns: 43px repeat(7, 1fr) 43px;

  @media screen and (min-width: 360px) {
    grid-template-columns: 47px repeat(7, 1fr) 47px;
  }

  @media screen and (min-width: 375px) {
    grid-template-columns: 49px repeat(7, 31px) 49px;
  }
}
.btnKeyboard {
  height: 43px;
  // background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-family: "Avenir";
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  text-transform: uppercase;

  // &.red,
  // &.black {
  //   color: #fff;
  // }
  // &.white {
  //   color: #d1091e;
  //   border: 1px solid #d1091e;
  // }
  // &.red {
  //   background-color: #d1091e;
  // }
  &.black {
    background-color: #3b3c3e;
  }

  &.enter {
    font-size: 11px;
  }
}

.base {
  background: white;
  color: black;
}

.baseDark {
  background: #686868;
  color: white;
}

.correct {
  background: var(--icon_1);
  border: "1px solid  var(--icon_1)";
  color: var(--background_1);
}

.correctSk {
  background: var(--icon_1);
  border: "1px solid  var(--icon_1)";
  color: var(--text_2);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
    inset 0 4px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.25);
}

.correctMagnum {
  background: var(--cta_1);
  border: "1px solid  var(--cta_1)";
  color: var(--background_1);
}

.almostMagnum {
  background: var(--cta_3);
  border: "1px solid var(--text_2)";
  border-color: var(--text_2);
  color: var(--text_1);
}

.almost {
  background: #fff;
  border: "1px solid var(--text_1)";
  border-color: var(--text_1);
  color: var(--text_1);
}

.wrong {
  background: var(--background_2);
  border: "1px solid  var(--background_2)";
  color: var(--text_4);
}

.wrongMagnum {
  background: #ED1C24;
  border: "1px solid #ED1C24";
  color: white;
}
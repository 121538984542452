.letterContainer {
  // width: 32px;
  // height: 32px;
  // background-color: #f8f8f8;
  // box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), inset 0 -5px 0 rgba(0, 0, 0, 0.1);
  // border-radius: 12px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-family: "Avenir", sans-serif;
  // font-weight: 800;
  // font-size: 16px;
  // line-height: 16px;
  transition: transform 250ms linear;
  // text-transform: uppercase;
  // color: #d1091e;
  // &.small {
  //   width: 24px;
  //   height: 24px;
  //   border-radius: 8px;
  //   font-size: 13px;
  //   line-height: 13px;
  //   &.red {
  //     box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2),
  //       inset 0 3px 0 rgba(255, 255, 255, 0.2),
  //       inset 0 -3px 0 rgba(0, 0, 0, 0.25);
  //   }
  // }
  // &.big {
  //   width: 45px;
  //   height: 45px;
  //   border-radius: 16px;
  //   font-size: 25px;
  // }
  // &.red,
  // &.black {
  //   color: #fff;
  // }
  // &.red {
  //   background-color: #d1091e;
  //   border: none;
  //   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
  //     inset 0 4px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.25);
  // }
  // &.black {
  //   background-color: #3b3c3e;
  //   border: 1px solid #3b3c3e;
  //   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
  //     inset 0 3px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.5);
  // }
  // &.white {
  //   color: #d1091e;
  //   background-color: #fff;
  //   border: 1px solid #d1091e;
  //   box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), inset 0 -5px 0 rgba(0, 0, 0, 0.1);
  // }
  // @media screen and (min-width: 768px) {
  //   &.big {
  //     width: 50px;
  //     height: 50px;
  //     font-size: 28px;
  //     line-height: 22px;
  //   }
  // }

  &.flip {
    transform: rotateX(90deg);
  }
}

.correct {
  background: var(--icon_1);
  border: "1px solid  var(--icon_1)";
  color: var(--background_1);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
    inset 0 4px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.25);
}

.correctSk {
  background: var(--icon_1);
  border: "1px solid  var(--icon_1)";
  color: var(--text_2);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
    inset 0 4px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.25);
}

.correctMagnum {
  background: var(--cta_1);
  border: "1px solid  var(--cta_1)";
  color: var(--background_1);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
    inset 0 4px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.25);
}

.almostMagnum {
  background: var(--cta_3);
  border: "1px solid var(--text_2)";
  border-color: var(--text_2);
  color: var(--text_1);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), inset 0 -5px 0 rgba(0, 0, 0, 0.1);
}

.almost {
  background: #fff;
  border: "1px solid var(--text_1)";
  border-color: var(--text_1);
  color: var(--text_1);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), inset 0 -5px 0 rgba(0, 0, 0, 0.1);
}

.wrong {
  background: var(--background_2);
  border: "1px solid  var(--background_2)";
  color: var(--text_4);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
    inset 0 3px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.5);
}

.wrongMagnum {
  background: #ED1C24;
  border: "1px solid #ED1C24";
  color: white;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2),
    inset 0 3px 0 rgba(255, 255, 255, 0.2), inset 0 -4px 0 rgba(0, 0, 0, 0.5);
}

.shake {
  animation: shake 250ms ease-in-out;
}

@keyframes shake {
  10% {
    transform: translateX(-5%);
  }

  30% {
    transform: translateX(5%);
  }

  50% {
    transform: translateX(-7.5%);
  }

  70% {
    transform: translateX(7.5%);
  }

  90% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(0);
  }
}
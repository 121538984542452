.boardWordle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 29px;
  gap: 5px;
  @media screen and (min-width: 768px) {
    gap: 8px;
  }
}

.boardRow {
  display: flex;
  flex-direction: row;
  gap: 5px;

  @media screen and (min-width: 768px) {
    gap: 8px;
  }
}
